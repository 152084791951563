export default async function (url, options) {
  await new Promise(r => setTimeout(r, 300))
  if (url.toLowerCase() != 'server_url/data')
    return {
      ok: false,
      status: 404,
    }
  if (!options || options.method.toLowerCase() != 'post' || !options.body)
    return {
      ok: false,
      status: 400,
    }
  try {
    let { test, slide, answers } = JSON.parse(options.body)
    return genNextSlide(test, slide, answers)
  } catch (err) {
    console.error(err)
    return {
      ok: false,
      status: 500
    }
  }
}

function successAnswer(body) {
  return {
    ok: true,
    status: 200,
    json: async () => JSON.parse(JSON.stringify(body))
  }
}

function genNextSlide(test, slide, answers) {
  if (test == 0) {
    if (slide == 7) {
      return successAnswer({
        ...Tests[0][7],
        text: resulttexts[answers[6] - 1] || 'error'
      })
    } else
      return successAnswer(Tests[0][slide])
  }

  if (test >= 1 || test <= 3) 
    return successAnswer(Tests[test][slide])

  if (test >= 4 && test <= 7) {
    if (slide == 0)
      return successAnswer(Tests[test][slide])
    else
      throw ('Для тестов 4-7 сейчас нет слайдов кроме стартового')
  }
  throw ('Тест ' + test + ' не существует')
}

//eslint-disable-next-line
const types = [
  'Start',
  'Description',
  'Question',
  'Result',
  'Final'
]


var Tests = [
  [
    {
      type: 'Start',
      img: getImagePath('t0.png'),
      title: 'Important exam',
      text: 'In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it.'
    },
    {
      type: 'Description',
      background: getBackground('bg0.jpg'),
      text:
        `
        <p>You are a student studying at one of the best universities in the world in year 3. At the moment 60% of your university cost is covered by a scholarship, and the remaining part is paid by your parents. You know that this is a significant amount for them, and they are very proud of you that you were able to get a scholarship!</p>
        <p>Last year, you did not succeed in studying for "A," the subjects were quite complex. And a couple of days ago you received a notification from the university that you could lose your scholarship. You understand that the university is very expensive and your parents will not be able to pay for it and will be very upset if you lose it.</p>
        <p>Very soon you have an important exam, on which your scholarship will depend or not. The exam is quite difficult and the teacher is picky. You tried as best you could, but managed to learn only half of the questions.</p>
      `,
      progress: 0
    },
    {
      type: 'Question',
      progress: 0,
      question: {
        name: 'q1',
        text: '<p>You wake up in the morning <br>on the day of the exam.</p>',
        question: 'What are you thinking of?',
        variants: [
          {
            value: '1',
            text: `I have prepared cheat sheets. <br>I will write them off!`
          },
          {
            value: '2',
            text: `Oh, I learned half of the questions. Someway I'll twist them out.`
          },
          {
            value: '3',
            text: `God, this is awful! <br>I will come across a ticket that I don’t know.`
          },
          {
            value: '4',
            text: `My odds are 50/50. If I will pass then I'm lucky. <br>But if I don't, I'll retake it.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 20,
      question: {
        name: 'q2',
        text: `<p>You got up, washed, and got dressed. When you go out, you realize that you are already a little late for the exam.</p> <br><p>You run to the bus station and get on the bus. After 5 minutes, the bus gets into traffic. There are 15 minutes to go to the university, and you are already very late.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: '1',
            text: `I will stay on the bus, but I will push the driver and speak in raised tones.`
          },
          {
            value: '2',
            text: `It doesn't matter. Okay, I'll just sleep.`
          },
          {
            value: '3',
            text: `I'll get off the bus and run, just to be on time.`,
            message: {
              content: `You just left, it's a very long run and you'd better get back on the bus. <br>Try choosing another option.`,
              type: 'try_again'
            }
          },
          {
            value: '4',
            text: `I will worry, but I will try to explain to myself that I cannot influence this.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 40,
      question: {
        name: 'q3',
        text: '<p>The bus has finally left, and you arrive at the university. You run down the corridor and approach the audience, waiting for your turn.</p>',
        question: 'What would be your reaction?',
        variants: [
          {
            value: '1',
            text: `My palms are sweating, I'm shaking my leg, and I'll start running to the toilet.`
          },
          {
            value: '2',
            text: `I'll sit and wait until I can go to Instagram.`
          },
          {
            value: '3',
            text: `I will go over the notes and read the questions that I did not have time to learn.`
          },
          {
            value: '4',
            text: `I will go to and fro.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 60,
      question: {
        name: 'q4',
        text: `<p>Your turn has come. You enter the classroom and see that only one seat is free in front of the teacher at the first desk. You come up to pull out a ticket and you see a question that you don't know.</p>`,
        question: `What will you do and say to yourself?`,
        variants: [
          {
            value: '1',
            text: `I'll get up and leave the exam, I won't pass it anyway.`,
            message: {
              content: `This is not the best option, we advise you to go back and try to pass the exam. Maybe luck is on your side today!`,
              type: 'try_again'
            }
          },
          {
            value: '2',
            text: `It will be difficult, but I will try to get a cheat sheet.`
          },
          {
            value: '3',
            text: `I'll tell the teacher something and get out.`
          },
          {
            value: '4',
            text: `Damn, I knew it, I'm an idiot, now I'm going to lose my scholarship.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 80,
      question: {
        name: 'q5',
        text: `<p>30 minutes after the exam passes, you understand that you will not be able to charm the teacher or solve the task on the exam ticket. At the moment, the teacher gets up and walks around the classroom in circles. You decide that the best option now is to get a cheat sheet. Plus, he's not sitting in front of you right now.</p> <p>The teacher walks behind and you calmly write off. You have already written more than half of the ticket and you are already starting to rejoice that it has passed. But then you realize that someone is breathing in the back. You understand that he stood all this time and saw that you were cheating.</p>`,
        question: 'What will you tell to the teacher?',
        variants: [
          {
            value: '1',
            text: `I’m sorry, could I drag another ticket and write again.`
          },
          {
            value: '2',
            text: `Apologize and leave. You start berating yourself in your head.`
          },
          {
            value: '3',
            text: `Barely holding back tears, you apologize.`
          },
          {
            value: '4',
            text: `Convince you didn't have a cheat sheet.`
          },
        ]
      }
    },
    {
      type: 'Result',
      progress: 100,
      text: ''
    },
    {
      type: 'Final',
      img: getImagePath('final0.jpg'),
      text: `
        <p>Based on this situation, we would say that you should work with <span style="font-weight: 600">anxiety</span>. It could prevent you from implementing your plans. We also want to note that you have stable <span style="font-weight: 600">self-esteem!</span></p>
        <p>For more accurate results, you could go through other games and see the general characteristics in the profile.</p>
      `,
      indicators: [
        ['Great emotional intellect'], // Зелёные
        ['Codependent relationship']   // Красные
      ],
    }
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t1.png'),
      title: "Frendship never dies",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    },
    {
      type: 'Description',
      text:
        `
        <p>You have a best friend and you were close for a long time. You have been friends for many years, and you have been through a lot: you have a&nbsp;lot&nbsp;of&nbsp;positive and negative memories.</p>

        <p>But for the last couple of months, you've realized that you are growing apart. You have less common interests and you are no longer so&nbsp;interested in the friendship.
        <br>
        Looks like they’ve changed: they started constantly talking about themselves, not interested in your life, and complaining about everything. But you also want to be supported and heard.</p>`,
      progress: 0
    },
    {
      type: 'Question',
      progress: 0,
      question: {
        name: 'q1',
        text: `<p>Now you're thinking about stopping friendship.</p>`,
        question: 'What would you do?',
        variants: [
          {
            value: '1',
            text: `I'll think it over again. <br>More carefully.`
          },
          {
            value: '2',
            text: `I will immediately write to them about stopping the friendship.`
          },
          {
            value: '3',
            text: `I'll nullify our relationship. I will ignore their messages and calls.`
          },
          {
            value: '4',
            text: `I will not pay attention to&nbsp;my&nbsp;feelings. I will continue to&nbsp;communicate.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 20,
      question: {
        name: 'q2',    
        text: `<p>You've decided to take some time to&nbsp;think it through. You've realized that you have already told your friend more than once that you also want to&nbsp;be&nbsp;heard. But they don't seem to&nbsp;care.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: '1',
            text: `I will stay in this relationship. I'll hope that they will understand the mistakes and change.`
          },
          {
            value: '2',
            text: `I will be cold and distant with&nbsp;them.`
          },
          {
            value: '3',
            text: `I'll just stop communicating with&nbsp;them, without explanation.`,
          },
          {
            value: '4',
            text: `I'll give them one last chance.        `
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 40,
      question: {
        name: 'q3',
        text: `<p>You walk down the street and think about the whole situation along the way. Approaching your house, you run into your friend. You greeted and chatted a little.</p>         <p>During the conversation, they realized that you treat them differently. </p>        <p>They want to talk to you to find out what happened.</p>`,
        question: `What would you say?`,
        variants: [
          {
            value: '1',
            text: `I'm not ready to talk right now. There are some things that I don't comfortable with. <br>Let's talk later.`,
            message: {
              content: `After a few excuses, you decided to talk. During the conversation, your friend says that they've heard your point. They agreed that they began to hear you less and promised to improve. You decided to give them one last chance.`,
              type: 'continue'
            }
          },
          {
            value: '2',
            text: `I don’t want to be friends <br>anymore.`,
            message: {
              content: `After a few excuses, you decided to talk. During the conversation, your friend says that they've heard your point. They agreed that they began to hear you less and promised to improve. You decided to give them one last chance.`,
              type: 'continue'
            }
          },
          {
            value: '3',
            text: `You're mistaken. <br>And I will continue to be cold.`,
            message: {
              content: `After a few excuses, you decided to talk. During the conversation, your friend says that they've heard your point. They agreed that they began to hear you less and promised to improve. You decided to give them one last chance.`,
              type: 'continue'
            }
          },
          {
            value: '4',
            text: `I'll be straight about my feelings. I'll say that I've reconsidered the friendship and I'm thinking about whether we should continue our relationship.        `,
            message: {
              content: `After a few excuses, you decided to talk. During the conversation, your friend says that they've heard your point. They agreed that they began to hear you less and promised to improve. You decided to give them one last chance.`,
              type: 'continue'
            }
          },
        ]

      }
    },
    {
      type: 'Question',
      progress: 60,
      question: {
        name: 'q4',
        text: `<p>A month has passed, and during this time you haven't seen any changes. By the end of the month, you realize you still want to break off your relationship. </p>`,
        question: `How do you feel?`,
        variants: [
          {
            value: '1',
            text: `The sadness about ending. <br>Anger that nothing worked out. Relief that everything will be over.`
          },
          {
            value: '2',
            text: `Anger because they've never understood anything and did nothing to improve our relationship. If they have done anything, then it would be fine with our relationship.`
          },
          {
            value: '3',
            text: `I don't feel anything.<br>I don't care.`,
          },
          {
            value: '4',
            text: `Sadness because they didn't do&nbsp;anything. I'm not important to&nbsp;them, and our relationship is&nbsp;not valuable either.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 80,
      question: {
        name: 'q5',
        text: `<p>You've finally decided to end your friendship. It no longer brings you positive emotions, you experience only negativity and fatigue from it. </p>`,
        question: `How do you feel?`,
        variants: [
          {
            value: '1',
            text: `I won't discuss anything. <br>I will bring the relationship to&nbsp;naught: I will answer and&nbsp;communicate less.`
          },
          {
            value: '2',
            text: `I will ask them to meet me. I&nbsp;will say that I no longer feel positive emotions from our friendship, and  I want to end our relationship.`
          },
          {
            value: '3',
            text: `I will continue our friendship. <br>I will hope that they will change and someday hear me. It's hard for me to end a relationship on my own.`,
          },
          {
            value: '4',
            text: `At the meeting, I will express everything that I have boiled. I&nbsp;will say that I will no longer speak with them.`
          },
        ]
      }
    },
    {
      type: 'Result',
      progress: 100,
      text: `You decide to talk to your friend after all. You meet and discuss everything. You tell them everything you feel. After your story, a friend asks for another chance, but you've decided not to give it and end your friendship. You are grateful to each other for all the good things between you and say goodbye.`
    },
    {
      type: 'Final',
      img: getImagePath('final1.png'),
      altspace: true,
      text: `
        <p>Based on this situation, we would say that you should work with <span style="font-weight: 600">anxiety</span>. It could prevent you from implementing your plans. We also want to note that you have stable <span style="font-weight: 600">self-esteem!</span></p>
        <p>For more accurate results, you could go through other games and see the general characteristics in the profile.</p>
      `,
      indicators: [
        ['Great emotional intellect'], // Зелёные
        ['Codependent relationship']   // Красные
      ],
    }    
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t2.png'),
      title: "Your career path",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    },
    {
      type: 'Description',
      text: `
      <p>Imagine you are in high school. It's time to think about your future profession. Your parents are well-paid doctors and they have been preparing you for a career as a doctor all your life.</p>
      <p>Your parents insist that you become a doctor too.<br>
        You will receive a high salary, they will find you a job in the best clinic, and your parents will help you.
      </p>
      <p>
        But then it's time to apply to the university, and you realize that you don't see yourself as a doctor, although you used to agree with this.
        Often you think about who exactly YOU want to be and realize that you've always wanted to be an artist. It lights you up.      
      </p>
      `,
      progress: 0
    },
    {
      type: 'Question',
      progress: 0,
      question: {
        name: 'q1',
        text: `<p>You realize that it is fascinating for you to become a real artist, it inspires you, and only the thought about it makes you happy.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: `1`,
            text: `I’ll choose the most reliable option and apply to a medical school. I don't want to upset my parents.`
          },
          {
            value: `2`,
            text: `I'll put my parents in front of the fact: either I apply wherever I want, or I stop communicating with them.`
          },
          {
            value: `3`,
            text: `I will analyze everything again and make a list of the pros and cons of both professions.`
          },
          {
            value: `4`,
            text: `I'll gather my courage and talk to my parents. I will say that I don't want to be a doctor and will apply to an art university.        `
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 25,
      question: {
        name: 'q2',
        text: `<p>You decide to think through the idea of being an artist. You've come to a conclusion: it is your dream. 
        <br>
        You think the best decision is to talk to your parents. You know they are quite categorical, the conversation will be serious and parents may need a lot of time to come to terms with it.</p>`,
        question: `How will you invite them to a&nbsp;conversation?`,
        variants: [
          {
            value: `1`,
            text: `As soon as my parents are home, I will start a&nbsp;conversation. `
          },
          {
            value: `2`,
            text: `It will take a long time to decide on this. Any moment seems to be inappropriate. After a while, I'll start a conversation.`
          },
          {
            value: `3`,
            text: `I won't tell them anything. I'll apply to the art school by myself. `,
            message: {
              content: `Maybe, it's not a bad idea to tell your parents about your desire? This is a huge and important step, and they have the right to know about your choice. Perhaps they will support you in your endeavor. But if they don't, then try to tell them what you want. After all, they are worried and want to be a part of your life.`,
              type: 'try_again'
            }
          },
          {
            value: `4`,
            text: `I'll choose a day off when everyone is at home. I'll calmly start a conversation.`
          },
          {
            value: `5`,
            text: `I will invite them to a restaurant and tell them I want to talk about university admission. I&nbsp;will&nbsp;tell them that it is important to me.`
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 50,
      question: {
        name: 'q3',
        text: `<p>It's time to talk. You sit in front of your parents. The conversation hasn't started yet. Parents look at you questioningly and wait for you to tell them.</p>`,
        question: `How do you feel?`,
        variants: [
          {
            value: `1`,
            text: `I'm calm. I will explain everything&nbsp;to&nbsp;them and hope they will understand me.`
          },
          {
            value: `2`,
            text: `I'm afraid they won't understand and I'll have to defend myself. My palms are sweating.`
          },
          {
            value: `3`,
            text: `I'm feeling guilty. I'm ruining their hopes and contribution to&nbsp;me.`
          },
          {
            value: `4`,
            text: `I'm feeling angry. They never support me in anything and now they'll say that I am a fool.<br> So I am angry in advance.`
          },
          {
            value: `5`,
            text: `I don't care, I'm calm. I will stand on my own.        `
          },
        ]
      }
    },
    {
      type: 'Question',
      progress: 75,
      question: {
        name: 'q4',
        text: `<p>During the conversation, you see that the parents are clearly unhappy. They get angry and start convincing you that you are confused, you decide on emotions, and in general, they have invested so much in you, and you just decide to radically change your plans. They also said that if you don't agree to go into medicine, then they completely stop providing. You're not working and you need their money.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: `1`,
            text: `I will start arguing with them, proving and shouting. I'll slam the door and go live with my&nbsp;friend.`,
            message: {              
              content: `We think it's better to continue the conversation. Maybe you can convince them?`,
              type: 'try_again',
              styles: 'text-align: center;'
            }
          },
          {
            value: `2`,
            text: `I will agree to their terms because I do not want to be left without money. Especially since I was prepared for medical school. In general, I will cope.`,
            message: {
              content: `Maybe it's worth talking to them again, rather than immediately agreeing with someone else's point of view. Maybe you can convince them?`,
              type: 'try_again',
              styles: 'text-align: center;'
            }
          },
          {
            value: `3`,
            text: `I will say that I don't need their money and I'll try to enroll in an art university on my own. I will work in parallel and maybe ask for help from my grandmother.`,
            message: {
              content: `You say that this option doesn't suit you and you don't agree with it. You ask them to understand you and tell them how you feel.`,
              type: 'continue'
            }
          },
          {
            value: `4`,
            text: `I will agree to study where they want. While studying I will earn extra money. And as soon as I save up money, in a year or two I will go where I want.        `,
            message: {
              content: `You say that this option doesn't suit you and you don't agree with it. You ask them to understand you and tell them how you feel.`,
              type: 'continue'
            }
          },
        ]
      }
    },
    {
      type: 'Result',
      progress: 100,
      text: 'Your conversation is coming to an end. You see that your parents are listening to you attentively and with interest. In the end, they agreed that you should go to an art university. Of course, they were upset. <br>But parents said they wish you the best path and believe that you will succeed.'
    },
    {
      type: 'Final',
      img: getImagePath('final2.png'),
      altspace: true,
      text: `
        <p>Based on this situation, we would say that you should work with <span style="font-weight: 600">anxiety</span>. It could prevent you from implementing your plans. We also want to note that you have stable <span style="font-weight: 600">self-esteem!</span></p>
        <p>For more accurate results, you could go through other games and see the general characteristics in the profile.</p>
      `,
      indicators: [
        ['Great emotional intellect'], // Зелёные
        ['Codependent relationship']   // Красные
      ],
    }

  ],
  [
    {
      type: 'Start',
      img: getImagePath('t3.png'),
      title: "Long awaited meeting",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    },
    {
      type: 'Description',
      text: `
      <p>You have a best friend with whom you have been friends since junior high. A couple of years ago she left for another city, you keep in touch, but you haven't seen each other all this time.</p> 
      <p>Recently, you received a message from her that she came to the city for a couple of days and offered to meet. She will only have an hour to see you, then she will need to leave. Of course, you agreed and are already looking forward to the meeting, during this time you have managed to get very missed for her. </p>
      <p>The day of the meeting has come, you decided to meet at your favorite bar at 6 pm. You have to walk to it for about 30 minutes. <br>It's 5:30 and you're leaving the house.</p>
      `,
      progress: 0
    },

    {
      type: 'Question',
      progress: 0,
      question: {
        name: 'q1',
        text: `<p>You leave the apartment and close the door. <br>There is a woman with a baby carriage on the stairwell who asks for help. She needs to lift the stroller and huge bags.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: `1`,
            text: `I'll politely say no because I don't want to be late.`
          },
          {
            value: `2`,
            text: `I'll pretend that I didn't hear and pass by.`
          },
          {
            value: `3`,
            text: `I'll help the woman: I'll bring the baby carriage, then I'll go down again and help lift the bags. When I'll walk down the street, I will speed up or run so as not to be late.`,
            message: {
              content: `You helped the girl lift everything she needed. You look at the clock and it's already 5:40. You've lost 10 minutes, and now you have to go very quickly.`,
              type: 'continue'
            }
          },
          {
            value: `4`,
            text: `I'll ask you to leave me alone and leave.`
          },
          {
            value: `5`,
            text: `I'll help the woman quickly: I'll bring the baby carriage. After that, I will immediately run to a meeting.        `,
            message: {
              content: `You helped the girl lift everything she needed. You look at the clock and it's already 5:40. You've lost 10 minutes, and now you have to go very quickly.`,
              type: 'continue'
            }
          },
        ]
      }
    },    
    {
      type: 'Question',
      progress: 20,
      question: {
        name: 'q2',
        text: `<p>You leave the entrance and walk towards the bar. Then you see that your grandmother is coming towards you. <br>You are greeting, hugging and Grandma starts telling you that she just got out of the bank and there she stood in a huge queue. Knowing your grandmother, you understand that this conversation can drag on for a long time.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: `1`,
            text: `I'll harshly say that I need to go, I'm late. Then I'll leave.`
          },
          {
            value: `2`,
            text: `I'll nervously wait until she finishes her story, looking at the time.`,
            message: {
              content: `Grandma keeps telling you how she spent today in all detail. Then her phone rings and while she's talking, you say goodbye and go to a meeting.<br><br>
              It's 5:50, and you're very late and already running down the street to catch up.`,
              type: 'continue'
            }
          },
          {
            value: `3`,
            text: `I'll say: sorry, I'm late. I'll come by tomorrow. I'm very glad to see you and I'm interested to hear your story, but now I have to run.`
          },
          {
            value: `4`,
            text: `I'll ask her to leave me alone and leave.`
          },
        ]
      }
    },    
    {
      type: 'Question',
      progress: 40,
      question: {
        name: 'q3',
        text: `<p>You left your grandmother and are walking down the street. You have 10 minutes to go.</p> <p>While you're walking, you get a call from your Mom. You answered the phone, your Mom says she urgently needs your help. <br>She forgot her keys and can't get home. She just came home from work, and no one is at home. <br>Your Mom lives on the other side of a city, and you have to go there in 30 minutes.</p>`,
        question: `What would you do?`,
        variants: [
          {
            value: `1`,
            text: `I'll go to Mom, not to inform my friend. I'll manage it somehow.`
          },
          {
            value: `2`,
            text: `I'll go to my Mom, and tell my friend I'll be late.`
          },
          {
            value: `3`,
            text: `I'll get irritated. Always something happens to her. <br>I can't help anything, busy.`
          },
          {
            value: `4`,
            text: `I'll say: sorry, I can't help you right now. I've set up a meeting with my friend, I'm far away. It's faster for you to wait for Dad.`
          },
          {
            value: `5`,
            text: `I'll say: Mom, I'm sorry, I'm busy right now. I understand that you want to relax at home. I can call neighbors, maybe they still have the keys to our apartment.        `
          },
        ]
      }
    },    
    {
      type: 'Question',
      progress: 60,
      question: {
        name: 'q4',
        text: `<p>You tried as much as possible to make sure that you didn't have to go to your Mom. But no one else picked up the phone and only you can help her. <br>You had to go. </p>
        <br>
          <p>It's 18:05, you're driving in a taxi to your Mom's and your friend calls you. </p>`,
        question: `What would say to your friend?`,
        variants: [
          {
            value: `1`,
            text: `I'll pick up the phone and start defending myself. I'll remind her how she was late a few years ago.`
          },
          {
            value: `2`,
            text: `I'll tell the truth about me going to help my Mom and apologize for it. I'll say that I can arrive in 20-30 minutes. I'll ask if she can wait for me.`
          },
          {
            value: `3`,
            text: `I'll lie to her. I'll say that I'm almost there, literally 5 minutes, and I'll be at the bar.`
          },
          {
            value: `4`,
            text: `I won't pick up the phone. <br>I hope I'll get there quickly.`
          },
        ]
      }
    },    
    {
      type: 'Question',
      progress: 80,
      question: {
        name: 'q5',        
        text: `<p>You helped Mom, and she is very grateful to you. <br>
        You immediately get into a taxi to a bar for a meeting. <br>
        You arrived at the bar 30 minutes past your agreed meeting time and noticed that your friend only has 30 minutes left before she needs to leave. Upon entering, you spotted your friend sitting alone and looking low.</p>`,
        question: `What would say to your friend?`,
        variants: [
          {
            value: `1`,
            text: `I'll say that I'm sorry about what happened. It was important for me to help my Mom. Please know that I truly regret it.`
          },
          {
            value: `2`,
            text: `I'll pretend that nothing happened. It's going to be an awkward evening anyway. I'll try to communicate as if nothing had happened. If she asks why I'm late, I'll change the subject.`
          },
          {
            value: `3`,
            text: `I'll say: well, you know, it's Mom. I couldn't do it any other way, cheer up.        `
          },
        ]
      },
    },
    {
      type: 'Result',
      progress: 100,
      text: `Your friend understood your position about the situation. For sure, she is upset that you have only 30 minutes to talk. <br>But after 5 minutes, you have already started conversing, and laughing and everything has returned as you've never split up.`
    },
    {
      type: 'Final',
      img: getImagePath('final3.png'),
      altspace: true,
      text: `
        <p>Based on this situation, we would say that you should work with <span style="font-weight: 600">anxiety</span>. It could prevent you from implementing your plans. We also want to note that you have stable <span style="font-weight: 600">self-esteem!</span></p>
        <p>For more accurate results, you could go through other games and see the general characteristics in the profile.</p>
      `,
      indicators: [
        ['Great emotional intellect'], // Зелёные
        ['Codependent relationship']   // Красные
      ],
    }          
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t4.png'),
      title: "Burning marriage",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    }
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t5.png'),
      title: "Romance is dead",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    }
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t6.png'),
      title: "Long time no date",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    }
  ],
  [
    {
      type: 'Start',
      img: getImagePath('t7.png'),
      title: "Unappreciated job",
      text: "In each game, we tried to choose the&nbsp;most relevant life situations. <br>Pick the option as you would do in&nbsp;real life, your statistics and your progress depend on it."
    }
  ]
]


var resulttexts = [
  `You pulled the ticket and you got the ticket that you taught! <br>You defended your ticket perfectly, but the teacher gave you a B because you cheated. As a result, you still have a scholarship and you continue your studies at the university!`,
  `You burst into tears and the teacher took pity on you and put C for the exam. They didn't save your full scholarship, now it will be 40%. Parents, of course, are upset, but you promised them that you would help with tuition fees.`,
  `You have been sent to retake. <br>Because you simply left the exam, your scholarship was reduced to 30%.<br> Parents, of course, are upset, but you promised them that you would help with tuition fees.`,
  `You confidently proved that you did not cheat, but the teacher saw everything with his own eyes. <br>As a result, this matter reached the dean's office, and professors checked everything on the cameras. It was visible that you were cheating. <br>You were sent for a retake and marked. <br>Your scholarship has been reduced to 30%. <br>Parents, of course, are upset, but you promised them that you would help with tuition fees.`
]

function getImagePath(img) {
  let res = require(`@/assets/img/tests/${img}`)
  console.log(res)
  return res
}

function getBackground(bg) {
  return `url(${require(`@/assets/img/tests/background/${bg}`)})`
}

var backgrounds = [
  `linear-gradient(180deg, #EBF1FE 52.08%, #EDF6D8 100%)`,
  `linear-gradient(168.93deg, #B8CDFF -0.75%, #E5D5FF 38.86%, #F3DFFF 99.3%)`,
  `linear-gradient(172.24deg, #EBF0FC 17.62%, #F0E1FF 67.01%, #84C5F9 100.09%)`,
  `linear-gradient(91.72deg, #F6E1F1 1.32%, #D8D0F8 52.93%, #CEDDF9 94.8%)`,
  `linear-gradient(173.51deg, #D1E1FC 3.23%, #DEE0FB 36.06%, #DEE6F9 68.79%, #C1E1F7 97.56%)`,
  `linear-gradient(173.51deg, #F3E5F9 3.23%, #E9EAF9 36.06%, #E3ECF9 68.79%, #EAD3D4 97.56%)`,
  `linear-gradient(186.65deg, #FAFEED 2.97%, #F3E3FC 67.07%, #E6F8EB 97.34%)`,
  `linear-gradient(186.65deg, #DEF6F9 2.97%, #FCFFF2 67.07%, #DCCFEA 97.34%)`
];

Tests.forEach((test, testindex) => test.forEach(slide=>{
  if (slide.background) return
  if (slide.type != 'Start')
    slide.background = backgrounds[testindex]
}))